<template>
  <div>
    <div class="section section-pd bg-pr-trans">
      <div class="page-header">
        <div
          class="container shape-container d-flex align-items-center text-center-sm"
        >
          <div class="col px-0">
            <div class="row align-items-center justify-content-center">
              <div class="col-lg-5">
                <h1 class="text-dark display-1 f-bold text-head mb-3">
                  Democratising Energy to make it accessible.
                </h1>
                <p class="f-18 text-dark mb-0">
                  Whether you are a consumer who wants to save on energy bills
                  and enjoy a little more power/ energy or a Renewable Energy
                  Solutions provider looking to grow your business, Imperium has
                  a tailor-made solution for you.
                </p>
              </div>
              <div class="col-md-7">
                <div class="text-center">
                  <img
                    class="headerimg"
                    src="/assets/images/svgs/lightbulb.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sectionPad pb-2">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <img
              src="/assets/images/svgs/lightbulb-amico.svg"
              class="img-fluid evaluationImg"
              alt="evaluation"
            />
          </div>
          <div class="col-md-6">
            <div class="evaluation">
              <h3 class="descriCount"><span>1</span></h3>
              <h2 class="text-primary f-bold">
                Building a renewable ecosystem
              </h2>
              <div class="mt-5">
                <div class="evaluateMedia mb-3 media">
                  <a href="#" class="d-flex"
                    ><img src="/assets/images/svgs/archive-tick.svg" alt=""
                  /></a>
                  <div class="media-body pl-2">
                    <p class="f-18">
                      At Imperium, we believe everyone should enjoy clean,
                      constant and reliable electricity without having to pay
                      through their noses for it.
                    </p>
                  </div>
                </div>
                <div class="evaluateMedia mb-3 media">
                  <a href="#" class="d-flex"
                    ><img src="/assets/images/svgs/archive-tick.svg" alt=""
                  /></a>
                  <div class="media-body pl-2">
                    <p class="f-18">
                      To make this possible, we have partnered with reputable
                      Renewable Energy organizations to deliver quality energy
                      efficient systems that provide clean and steady
                      electricity at your fingertips. Imperium also offers homes
                      and businesses simple and affordable ways to pay for
                      electricity using any of the innovative financing options
                      available on the platform
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sectionPad pt-5">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6 display-sm">
            <img
              src="/assets/images/svgs/outer-space.svg"
              class="img-fluid evaluationImg"
              alt="evaluation"
            />
          </div>
          <div class="col-md-6">
            <div class="evaluation">
              <h3 class="descriCount"><span>2</span></h3>
              <h2 class="text-primary f-bold">Our Promise of better living.</h2>
              <div class="mt-5">
                <div class="evaluateMedia mb-3 media">
                  <a href="#" class="d-flex"
                    ><img src="/assets/images/svgs/archive-tick.svg" alt=""
                  /></a>
                  <div class="media-body pl-2">
                    <p class="f-18">
                      Replace your hazardous and expensive fuel generators and
                      lanterns with modern solar electricity that can power
                      lights, televisions, mobile phones, fans, computers,
                      blenders, fridges and other electronic devices- giving you
                      a better living.
                    </p>
                  </div>
                </div>
                <div class="evaluateMedia mb-3 media">
                  <a href="#" class="d-flex"
                    ><img src="/assets/images/svgs/archive-tick.svg" alt=""
                  /></a>
                  <div class="media-body pl-2">
                    <p class="f-18">Join us today for a brighter future.</p>
                  </div>
                </div>
                <div class="evaluateMedia media">
                  <router-link
                    to="/energy-calculator"
                    rel="noopener noreferrer"
                    class="btn btn-imp-secondary bg-primary text-white btn-icon mt-2 py-3 px-5"
                  >
                    <span class="nav-link-inner--text">Find a solution</span>
                  </router-link>
                  <!-- target="_blank" -->
                  <!-- <router-link
                    to="/energy-calculator"
                    class="
                      btn btn-imp-secondary
                      bg-primary
                      text-white
                      btn-icon
                      mt-2
                      py-3
                      px-5
                    "
                  >
                  </router-link> -->
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 hidden-sm">
            <img
              src="/assets/images/svgs/outer-space.svg"
              class="img-fluid evaluationImg"
              alt="evaluation"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Calculator",
  components: {},
  data() {
    return {
      calculation: "general",
    };
  },
};
</script>

<style lang="scss">
.calHeading {
  display: flex;
  h2 {
    font-weight: bold;
    font-size: 32.256px;
    color: #2b2b2b;
    display: inline-block;
    margin-bottom: 0px;
  }
}
.basedClass {
  background: #e0ecce;
  border-radius: 19px;
  display: inline-block;
  div {
    display: inline-block;
    font-weight: 500;
    font-size: 14.9652px;
    color: #255e13;
    padding: 7px 32px;
    line-height: 51px;
    cursor: pointer;
    &.active {
      background-color: #219653;
      border-radius: 19px;
      color: white;
    }
  }
}
.appliance {
  color: black;
  background: #0000000a;
  padding: 16px;
  margin-bottom: 19px;
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  span {
    font-size: 31px;
    padding: 1px 7px;
    cursor: pointer;
  }
  .name {
    margin: 0;
    font-weight: bold;
  }
  .descr {
    margin: 0;
    font-size: 14px;
    opacity: 0.6;
  }
}
.bg-prp {
  background: #f5fcf2;
}
@media only screen and (max-width: 768px) {
  .headerimg {
    width: 72%;
    padding-top: 60px;
  }
  .basedClass {
    margin-top: 20px;
  }
  .formView {
    margin-top: 0px !important;
    background: transparent;
  }
  .maintenn {
    margin-top: 45px;
  }
  .yourCal {
    padding: 0px;
    border: none;
  }
}
.evaluation .evaluateMedia p {
  font-weight: 300;
  font-size: 18px;
  color: #202020;
}
</style>
