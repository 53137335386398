var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_c('div',{staticClass:"sectionPad pt-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_vm._m(2),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"evaluation"},[_vm._m(3),_c('h2',{staticClass:"text-primary f-bold"},[_vm._v("Our Promise of better living.")]),_c('div',{staticClass:"mt-5"},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"evaluateMedia media"},[_c('router-link',{staticClass:"btn btn-imp-secondary bg-primary text-white btn-icon mt-2 py-3 px-5",attrs:{"to":"/energy-calculator","rel":"noopener noreferrer"}},[_c('span',{staticClass:"nav-link-inner--text"},[_vm._v("Find a solution")])])],1)])])]),_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section section-pd bg-pr-trans"},[_c('div',{staticClass:"page-header"},[_c('div',{staticClass:"container shape-container d-flex align-items-center text-center-sm"},[_c('div',{staticClass:"col px-0"},[_c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-lg-5"},[_c('h1',{staticClass:"text-dark display-1 f-bold text-head mb-3"},[_vm._v(" Democratising Energy to make it accessible. ")]),_c('p',{staticClass:"f-18 text-dark mb-0"},[_vm._v(" Whether you are a consumer who wants to save on energy bills and enjoy a little more power/ energy or a Renewable Energy Solutions provider looking to grow your business, Imperium has a tailor-made solution for you. ")])]),_c('div',{staticClass:"col-md-7"},[_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"headerimg",attrs:{"src":"/assets/images/svgs/lightbulb.svg","alt":""}})])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sectionPad pb-2"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6"},[_c('img',{staticClass:"img-fluid evaluationImg",attrs:{"src":"/assets/images/svgs/lightbulb-amico.svg","alt":"evaluation"}})]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"evaluation"},[_c('h3',{staticClass:"descriCount"},[_c('span',[_vm._v("1")])]),_c('h2',{staticClass:"text-primary f-bold"},[_vm._v(" Building a renewable ecosystem ")]),_c('div',{staticClass:"mt-5"},[_c('div',{staticClass:"evaluateMedia mb-3 media"},[_c('a',{staticClass:"d-flex",attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/assets/images/svgs/archive-tick.svg","alt":""}})]),_c('div',{staticClass:"media-body pl-2"},[_c('p',{staticClass:"f-18"},[_vm._v(" At Imperium, we believe everyone should enjoy clean, constant and reliable electricity without having to pay through their noses for it. ")])])]),_c('div',{staticClass:"evaluateMedia mb-3 media"},[_c('a',{staticClass:"d-flex",attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/assets/images/svgs/archive-tick.svg","alt":""}})]),_c('div',{staticClass:"media-body pl-2"},[_c('p',{staticClass:"f-18"},[_vm._v(" To make this possible, we have partnered with reputable Renewable Energy organizations to deliver quality energy efficient systems that provide clean and steady electricity at your fingertips. Imperium also offers homes and businesses simple and affordable ways to pay for electricity using any of the innovative financing options available on the platform ")])])])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 display-sm"},[_c('img',{staticClass:"img-fluid evaluationImg",attrs:{"src":"/assets/images/svgs/outer-space.svg","alt":"evaluation"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"descriCount"},[_c('span',[_vm._v("2")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"evaluateMedia mb-3 media"},[_c('a',{staticClass:"d-flex",attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/assets/images/svgs/archive-tick.svg","alt":""}})]),_c('div',{staticClass:"media-body pl-2"},[_c('p',{staticClass:"f-18"},[_vm._v(" Replace your hazardous and expensive fuel generators and lanterns with modern solar electricity that can power lights, televisions, mobile phones, fans, computers, blenders, fridges and other electronic devices- giving you a better living. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"evaluateMedia mb-3 media"},[_c('a',{staticClass:"d-flex",attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/assets/images/svgs/archive-tick.svg","alt":""}})]),_c('div',{staticClass:"media-body pl-2"},[_c('p',{staticClass:"f-18"},[_vm._v("Join us today for a brighter future.")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6 hidden-sm"},[_c('img',{staticClass:"img-fluid evaluationImg",attrs:{"src":"/assets/images/svgs/outer-space.svg","alt":"evaluation"}})])
}]

export { render, staticRenderFns }